import styled from 'styled-components';

export const SectionWrapper = styled.section`
  padding: 80px 0;
  background-color: #f9f9f9;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;
