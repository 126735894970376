import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import loadable from '@loadable/component';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { cryptoTheme } from 'common/src/theme/crypto';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../../containers/Crypto/crypto.style';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import Navbar from '../../containers/Crypto/Navbar';
import BannerSectionNoWords from '../../containers/Crypto/BannerSectionNoWords';
import AboutUsComponent from '../../containers/Crypto/AboutUs';
import SEO from '../../components/seo';
import store from '../../store';
import Button from 'common/src/components/Button';
import Banner from '../../containers/Crypto/BannerSection';
import Footer from '../../containers/Crypto/Footer2';
import CryptoSlides from '../../containers/Crypto/CryptoSlides';
import TextCenteredAboutUs from '../../containers/Crypto/TextCenteredAboutUs';
import LeftAlignedContent from '../../containers/Crypto/LeftAlignedContent';
import LeftAlignedTextWithImage from '../../containers/Crypto/LeftAlignedTextWithImage';
import AppVideo from '../../containers/Crypto/AboutUsPage/MeetTheFounder';
import fannieMaeImage from 'common/src/assets/image/crypto/blueMultifamily.png';
import ImageTextCard from '../../containers/Crypto/ImageTextCard';
import ProductEditorial from '../../containers/Crypto/BuilderIOComponents/ProductEditorial';

const MillionsServed = loadable(() =>
  import('../../containers/Crypto/MillionsServedHomepage')
);

const FannieMaeMultifamilyAboutPage = () => {
  return (
    <ThemeProvider theme={cryptoTheme}>
      <Provider store={store}>
        <Fragment>
          <SEO title="Best Finance | Simple, Online, Fast" />
          <ResetCSS />
          <GlobalStyle />
          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            {/*             <TextCenteredAboutUs
              title={{
                content: 'Fannie Mae/Freddie Mac programs',
                fontSize: ['24px', '26px', '30px', '36px', '40px'],
                lineHeight: ['32px', '32px', '40px', '50px', '55px'],
                fontWeight: '700',
                color: '#FFFFFF',
                letterSpacing: '-0.010em',
                mb: '20px',
                textAlign: 'center',
              }}
              description={{
                content: 'Better down payment and rates with these programs.',
                fontSize: '16px',
                fontWeight: '400',
                color: '#FFFFFF',
                lineHeight: '28px',
                mb: ['25px', '25px', '30px', '30px', '45px'],
                textAlign: 'center',
              }}
              paddingTop="150px"
            />
            <LeftAlignedTextWithImage
              title={{
                content: 'Fannie Mae/Freddie Mac loans for all property sizes',
                fontSize: ['24px', '26px', '30px', '36px', '40px'],
                fontWeight: '700',
                color: '#000000',
                mb: '20px',
              }}
              description={{
                content:
                  'Best Finance has the ability to arrange Fannie Mae/Freddie Mac loans on properties ranging from 1 Million to 100 Million.  We have been able to secure Fannie Mae/Freddie Mac loans up to 80% Loan to Value on Multifamily, Affordable Housing, Seniors Housing, and Small Balance conventional loan.  We deal in acquisition and refinancing loans for multifamily and seniors housing projects of all sizes across the country.',
                fontSize: '16px',
                fontWeight: '400',
                color: '#525f7f',
                lineHeight: '28px',
                mb: '0',
              }}
              imageStyle={{ width: '60%', height: 'auto' }} // Adjusted image size
              imageSrc={fannieMaeImage}
              line1="✔ 1M - 100M loan amounts"
              line2="✔ Up to 80%"
              line3="✔ Multifamily, senior homes, small balance, affordable housing"
            />
            <MillionsServed
              card1Title="Trusted"
              card1Text="for multifamily"
              card2Title="100's"
              card2Text="of members across the US"
              card3Title="National"
              card3Text="Coverage"
            />
            <ImageTextCard
              imageSrc={fannieMaeImage}
              line1="First line of text"
              line2="Second line of text"
              line3="Third line of text, bold and larger"
              line4="Fourth line of text"
            /> */}
            {/* blogcomponent */}
            <ProductEditorial
            /* className="blog-article"
              content={article}
              options={{ includeRefs: true }} */
            />
            <Footer />
          </ContentWrapper>
        </Fragment>
      </Provider>
    </ThemeProvider>
  );
};

export default FannieMaeMultifamilyAboutPage;
