import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import { SectionWrapper, ContentWrapper } from './leftAlignedContent.style';

const LeftAlignedContent = ({ title, description }) => {
  return (
    <SectionWrapper>
      <Container>
        <ContentWrapper>
          <Box>
            <Heading
              as="h2"
              content={title.content}
              fontSize={title.fontSize}
              fontWeight={title.fontWeight}
              color={title.color}
              mb={title.mb}
              textAlign="left"
            />
            <Text
              content={description.content}
              fontSize={description.fontSize}
              fontWeight={description.fontWeight}
              color={description.color}
              lineHeight={description.lineHeight}
              mb={description.mb}
              textAlign="left"
              style={{ width: '100%' }} // Stretch text across the page
            />
          </Box>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

LeftAlignedContent.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
};

LeftAlignedContent.defaultProps = {
  title: {
    content: 'Left Aligned Content',
    fontSize: ['24px', '26px', '30px', '36px', '40px'],
    fontWeight: '700',
    color: '#000000',
    mb: '20px',
  },
  description: {
    content:
      'This is an example of left-aligned content that stretches across the page.',
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: '0',
  },
};

export default LeftAlignedContent;
