import React from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Image from 'common/src/components/Image';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import { TransactionsWrapper } from './transaction.style';
import blueMultifamily from 'common/src/assets/image/crypto/blueMultifamily.png';

const LeftAlignedTextWithImage = ({
  row,
  col1,
  col2,
  title,
  description,
  description2,
  btnStyle,
  imageSrc,
  imageStyle,
  line1,
  line2,
  line3,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      cryptoJson {
        TRANSACTIONS_FEATURE {
          title
          des
          image {
            publicURL
          }
        }
      }
    }
  `);

  const onFinishPurchase = async () => {
    navigate('/buy-pages/buy-process/');
  };

  const onFinishRefinance = async () => {
    navigate('/refi-pages/refinance-reasons/');
  };

  return (
    <TransactionsWrapper id="MortgagesDontHaveToBeDifficult">
      <Container>
        <Box className="row" {...row}>
          <Box
            className="colleft"
            {...col1}
            style={{ flexDirection: 'column' }}
          >
            <FeatureBlock
              title={<Heading content="" {...title} />}
              description={<Text content="" {...description} />}
            />
            &nbsp;
            <Text content={line1} {...description2} />
            &nbsp;
            <Text content={line2} {...description2} />
            &nbsp;
            <Text content={line3} {...description2} />
            &nbsp;
            <Box
              className="row"
              {...row}
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                padding: '10px',
              }}
            >
              <Button
                onClick={onFinishPurchase}
                title="Purchase"
                {...btnStyle}
                style={{ width: '45%' }}
              />
              &nbsp;
              <Button
                onClick={onFinishRefinance}
                title="Refinance"
                {...btnStyle}
                style={{ width: '45%' }}
              />
            </Box>
          </Box>
          <Box
            className="colright"
            {...col2}
            style={{
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image
              src={blueMultifamily}
              style={imageStyle}
              style={{ ...imageStyle, width: '80%', height: 'auto' }}
            />
          </Box>
        </Box>
      </Container>
    </TransactionsWrapper>
  );
};

// Transactions style props
LeftAlignedTextWithImage.propTypes = {
  row: PropTypes.object,
  col1: PropTypes.object,
  col2: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  description2: PropTypes.object,
  btnStyle: PropTypes.object,
  imageSrc: PropTypes.string,
  imageStyle: PropTypes.object,
  line1: PropTypes.string,
  line2: PropTypes.string,
  line3: PropTypes.string,
};

// Transactions default style
LeftAlignedTextWithImage.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  col1: {
    pr: '15px',
    pl: '15px',
    width: [1, 1 / 2, 1 / 2, 1 / 2, 1 / 2],
    flexBox: true,
    alignSelf: 'center',
  },
  col2: {
    pr: '15px',
    pl: '15px',
    width: [1, 1 / 2, 1 / 2, 1 / 2, 1 / 2],
    flexBox: true,
    alignSelf: 'center',
  },
  title: {
    fontSize: ['24px', '26px', '30px', '36px', '40px'],
    lineHeight: ['30px', '32px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#000000',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '100%', '415px'],
  },
  description: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['30px', '30px', '40px', '40px', '55px'],
    maxWidth: ['100%', '100%', '100%', '100%', '430px'],
  },
  description2: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['10px', '10px', '10px', '10px', '10px'],
    maxWidth: ['100%', '100%', '100%', '100%', '430px'],
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },

  imageStyle: {
    width: '100%',
    height: 'auto',
  },
  line1: '✔ 1M - 100M loan amounts',
  line2: '✔ Up to 80%',
  line3: '✔ Multifamily, senior homes, small balance, affordable housing',
};

export default LeftAlignedTextWithImage;
